import request from "@/request";

// 选择器
// 装帧类型
export const listBindingCategory = () => request.get('/api/bookshelf/chooses/?category=binding')

// 纸张类型
export const listPaperCategory = () => request.get('/api/bookshelf/chooses/?category=paper')

// 开本类型
export const listFormatCategory = () => request.get('/api/bookshelf/chooses/?category=format')

// 查询选择
export const listChoose = ( params ) => request.get('/api/bookshelf/chooses/', { params })

// 创建选择
export const addChoose = ( data ) => request.post('/api/bookshelf/chooses/', { data })

// 更新选择信息
export const changeChoose = ( data, id ) => request.patch(`/api/bookshelf/chooses/${id}/`, { data })

// 删除选择
export const delChoose = ( id ) => request.delete(`/api/bookshelf/chooses/${id}/`)