import request from "@/request";
/*
 * @Description: 获取用户列表
 * @Author: 张宇童
 * @Date: 2020/9/13 14:29
 */
export const getBookList = (params) =>
  request.get("/api/admin/books/", { params });
  
export const getBookDetail = (id) => request.get(`/api/admin/books/${id}/`);

export const updateBookInfo = (data) => request.patch(`/api/admin/books/${data.id}/`, data);

export const getBookCategories = (params) => request.get(`/api/bookshelf/categories/`,{params});

export const postBook=data=>request.post(`/api/admin/books/`,data,{"headers": {"id": data.id}})

export const getCosKey=()=>request.get(`/api/key/`)

export const deleteBook=id=>request.delete(`/api/admin/books/${id}/`)

export const updateCate=(data,id)=>request.patch(`/api/admin/books/${id}/`,data)
